import React, { useState, useEffect } from "react";
import Layout from "../../hocs/Layout";
import '../../css/FechaHorario.css';
import MultiDatePicker from "../../components/MultiDatePicker/MultiDatePicker";
import Horarios from "../../components/Horarios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getSolicitud, setFecha, setHorarios } from "../../redux/actions/solicitud";

const FechaHorario = ({ pedido, fecha_creacion, fecha_resolucion, setFecha, setHorarios, horarios_selected, getSolicitud }) => {
    const [currentDates, setCurrentDates] = useState({
        startDate: null,
        endDate: null
    });

    useEffect(() => {
        // Retrieve horarios from session storage on component mount
        const savedHorarios = JSON.parse(sessionStorage.getItem('horarios_selected')) || [];
        
        if (savedHorarios.length > 0) {
            setHorarios(savedHorarios);
        }

        // Set initial date on component mount
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const startDate = fecha_creacion ? new Date(fecha_creacion) : today;
        const endDate = fecha_resolucion ? new Date(fecha_resolucion) : today;

        setCurrentDates({
            startDate: startDate,
            endDate: endDate
        });

        setFecha({
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        });
    }, [fecha_creacion, fecha_resolucion, setFecha, setHorarios]);
    const horariosOptions = [
        { value: '1', label: '8.31 - 9.50', start: 8, end: 9, minuteStart: 31, minuteEnd: 50 },
        { value: '2', label: '10.01 - 11.20', start: 10, end: 11, minuteStart: 1, minuteEnd: 20 },
        { value: '3', label: '11.31 - 12.50', start: 11, end: 12, minuteStart: 31, minuteEnd: 50 },
        { value: '4', label: '13.01 - 14.20', start: 13, end: 14, minuteStart: 1, minuteEnd: 20 },
        { value: '5', label: '14.31 - 15.50', start: 14, end: 15, minuteStart: 31, minuteEnd: 50 },
        { value: '6', label: '16.01 - 17.20', start: 16, end: 17, minuteStart: 1, minuteEnd: 20 },
        { value: '7', label: '17.31 - 18.50', start: 17, end: 18, minuteStart: 31, minuteEnd: 50 },
        { value: '8', label: '19.01 - 20.20', start: 19, end: 20, minuteStart: 1, minuteEnd: 20 },
        { value: '9', label: '20.31 - 21.50', start: 20, end: 21, minuteStart: 31, minuteEnd: 50 },
        { value: '10', label: '22.51 - 23.30', start: 22, end: 23, minuteStart: 51, minuteEnd: 30 },
    ];

    const updateDatesWithHorarios = (dates, selectedHorarios) => {
        if (selectedHorarios.length > 0 && dates.startDate && dates.endDate) {
            const startDate = new Date(dates.startDate);
            const endDate = new Date(dates.endDate);

            const earliestHorario = Math.min(...selectedHorarios.map(Number));
            const latestHorario = Math.max(...selectedHorarios.map(Number));

            const earliest = horariosOptions.find(h => Number(h.value) === earliestHorario);
            const latest = horariosOptions.find(h => Number(h.value) === latestHorario);

            startDate.setHours(earliest.start, earliest.minuteStart, 0, 0);
            endDate.setHours(latest.end, latest.minuteEnd, 0, 0);

            return { startDate, endDate };
        }
        return dates;
    };

    const handleMultiDatePickerChange = (values) => {
        const updatedDates = updateDatesWithHorarios(values, horarios_selected);
        setCurrentDates(updatedDates);

        if (updatedDates.startDate && updatedDates.endDate) {
            setFecha({
                startDate: updatedDates.startDate.toISOString(),
                endDate: updatedDates.endDate.toISOString(),
            });
        }
    };

    const handleHorarioChange = (selectedHorarios) => {
        // Save the selected horarios in session storage
        sessionStorage.setItem('horarios_selected', JSON.stringify(selectedHorarios));

        setHorarios(selectedHorarios);
        
        const updatedDates = updateDatesWithHorarios(currentDates, selectedHorarios);
        setCurrentDates(updatedDates);

        if (updatedDates.startDate && updatedDates.endDate) {
            setFecha({
                startDate: updatedDates.startDate.toISOString(),
                endDate: updatedDates.endDate.toISOString(),
            });
        }
    };

    const isContinueDisabled = horarios_selected.length === 0;

    return (
        <Layout>
            <main className="fecha_horario">
                <div className="container">
                    <section className="fecha_horario_seleccion">
                        <MultiDatePicker
                            onChange={handleMultiDatePickerChange}
                            fechaCreacion={fecha_creacion ? fecha_creacion : null}
                            fechaResolucion={fecha_resolucion ? fecha_resolucion : null}
                        />
                        <Horarios horariosSelected={horarios_selected} onChange={handleHorarioChange} />
                    </section>
                    <section className="fecha_horario_botones">
                        <Link to={`../request/${pedido}`}><button className='button'>Volver</button></Link>
                        <Link to='/carroproductos'>
                            <button className='button' disabled={isContinueDisabled}>
                                Continuar
                            </button>
                        </Link>
                    </section>
                </div>
            </main>
        </Layout>
    );
};


const mapStateToProps = state => ({
    user: state.Auth.user,
    pedido: state.Solicitud.pedido,
    fecha_creacion: state.Solicitud.fecha_creacion,
    fecha_resolucion: state.Solicitud.fecha_resolucion,
    horarios_selected: state.Solicitud.horarios_selected
});

export default connect(mapStateToProps, {
    getSolicitud,
    setFecha,
    setHorarios
})(FechaHorario);
