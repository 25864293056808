import React, { useState, useEffect, useCallback } from 'react';
import '../css/SolicitudList.css';
import { connect } from 'react-redux';
import { get_pedidos } from '../redux/actions/pedidos'; 
import { Link } from 'react-router-dom';
import en_proceso from '../images/en_proceso.svg';
import entregado from '../images/entregado.svg';
import finalizado from '../images/entregado.svg';
import listo_para_retiro from '../images/listo_para_retiro.svg';
import rechazado from '../images/rechazado.svg';
import eyeblue from '../images/eyeblue.svg';
import Showitems from './Showitems'; 
import EditIcon from '../images/Edit.svg';
import recibida from '../images/Recibida.svg';
import { MdDelete, MdOutlineInsertComment } from "react-icons/md";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { BsDashLg } from "react-icons/bs";
import { ImBlocked } from "react-icons/im";
import Overlay from './Overlay'; 
import Paginator from './Paginator';
import CheckListProf from './CheckListProf';

const SolicitudList = ({ pedidos, get_pedidos, cantidadPaginas}) => {
  const [mostrarComponente, setMostrarComponente] = useState(false);
  const [idPedidoSeleccionado, setIdPedidoSeleccionado] = useState(null);
  const [selectedPedido, setSelectedPedido] = useState(null);
  const [showCheckList, setShowCheckList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayMessage, setOverlayMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState({
    pageSize: 25,
    page: 1,
    estado: '',
    fechaCreacion: 'undefined',
    fechaCreacionDate: 'undefined',
    fechaResolucion: 'undefined',
    estadoOrder: 'undefined',
    fechaCreacionOrder: 'undefined',
    fechaCreacionDateOrder: 'desc',
    fechaResolucionOrder: 'undefined',
  });

  const handleMostrarComponente = useCallback((pedidoId) => {
    setIdPedidoSeleccionado(pedidoId);
    setMostrarComponente(true);
  }, []);

  const handleOverlay = (message, confirmation = false) => {
    setOverlayMessage(message);
    setShowConfirmation(confirmation);
    setShowOverlay(true);
  };
  const handleShowItems = (pedido) => {
    setSelectedPedido(pedido);
    setMostrarComponente(true);
  };

  const handleShowCheckList = (pedido) => {
    setSelectedPedido(pedido);
    setShowCheckList(true);
  };
  const handleConfirmCancel = () => {
    // Implement cancel logic here
    setShowOverlay(false);
  };

  useEffect(() => {
    get_pedidos(selectedFilters);
  }, [selectedFilters]);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem('idPedido');
    sessionStorage.removeItem('horarios_selected');
    sessionStorage.removeItem('descripcionTaller')
    sessionStorage.removeItem('selectedTaller');
    get_pedidos(selectedFilters)
      .then(() => setLoading(false))
      .catch(error => setLoading(false));
  }, [get_pedidos]);

  const obtenerImagenEstado = (estado) => {
    switch (estado) {
      case 'Recibida':
        return recibida;
      case 'En proceso':
        return en_proceso;
      case 'Entregado':
        return entregado;
      case 'Finalizado':
          return finalizado;
      case 'Listo para retiro':
        return listo_para_retiro;
      case 'Rechazado':
        return rechazado;
      default:
        return null;
    }
  };
  const obtenerAccion = (estado, idPedido, descripcion) => {
    switch (estado) {
      case 'Recibida':
        return( <div><Link to={`/request/${idPedido}`} style={{ color: '#00A09A' }}> <img src={EditIcon} alt="" />  </Link>
         <div style={{ color: "#00A09A", cursor: "pointer" }} onClick={() => handleOverlay('¿Desea cancelar esta solicitud?', true)}>
            <MdDelete size={26} />
          </div>
        </div>)
      
      case 'Rechazado':
        return (
          <div style={{ color: "#00A09A", cursor: "pointer" }} onClick={() => handleOverlay( `Solicitud rechazada por administrador ${descripcion}`)}>
            <MdOutlineInsertComment size={26} />
          </div>
        );
  
      case 'En proceso':
        return (
          <div style={{ color: "#00A09A", cursor: "pointer" }} onClick={() => handleOverlay(`¿Desea cancelar esta solicitud? ${descripcion}`, true)}>
            <MdDelete size={26} />
          </div>
        );
      
      case 'Entregado':
        return (
          <div style={{ color: "#00A09A", cursor: "pointer" }} onClick={() => handleOverlay(`Entregado ${descripcion}`)}>
            <MdOutlineInsertComment size={26} />
          </div>
        );
      case 'Finalizado':
          return (
            <div style={{ color: "#00A09A", cursor: "pointer" }} onClick={() => handleOverlay(`Finalizado ${descripcion}`)}>
              <MdOutlineInsertComment size={26} />
            </div>
          );
      
      case 'Listo para retiro':
        return (
          <div style={{ color: "#00A09A", cursor: "pointer" }} onClick={() => handleOverlay(`Listo para retiro ${descripcion}`)}>
            <MdOutlineInsertComment size={26} />
          </div>
        );
      
      default:
        return (
          <div style={{ color: "#00A09A" }}>
            <ImBlocked size={24} />
          </div>
        );
    }
  };
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, wait);
    };
  };
  const debouncedGetPedidos = useCallback(
    debounce((filters) => {
      get_pedidos(filters);
    }, 300),
    [get_pedidos]
  );

  const handlePageSize = (e) => {
    const value = parseInt(e.target.value, 10);
    setSelectedFilters({ ...selectedFilters, pageSize: value });
  }

  const handlePageChange = (page) => {
    setSelectedFilters(prevFilters => ({ ...prevFilters, page }));
  };

  useEffect(() => {
    debouncedGetPedidos(selectedFilters);
  }, [selectedFilters, debouncedGetPedidos]);

  const handleStateChange = (e) => {
    const value = e.target.value;
    setSelectedFilters({ ...selectedFilters, estado: value });
  }

  const ESTADOS = {
    undefined: 'Todos',
    'Recibida': 'Recibida',
    'En proceso': 'En proceso',
    'Entregado': 'Entregado',
    'Finalizado':'Finalizado',
    'Listo para retiro': 'Listo para retiro',
    'Rechazado': 'Rechazado',
  };

  const handleSortChange = (filter) => {
    const order = selectedFilters[filter];
    const newOrder = order === 'undefined' ? 'desc' : order === 'desc' ? 'asc' : 'undefined';
    
    setSelectedFilters(prevFilters => {
      const updatedFilters = {
        ...prevFilters,
        estadoOrder: 'undefined',
        fechaCreacionDateOrder: 'undefined',
        fechaCreacionOrder: 'undefined',
        fechaResolucionOrder: 'undefined',
        [filter]: newOrder
      };
      return updatedFilters;
    });
  };
  return (
    <div className='solicitudes'>
      <h2>Solicitudes</h2>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <>
          <form className='filtros-form'>
            <label>
              Cant. solicitudes:
              <select
                className='filtros-form-select'
                name="pageSize" 
                id="pageSize" 
                value={selectedFilters.pageSize}
                onChange={handlePageSize} >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
              </select>
            </label>
          </form>
          <table className='tabla-solicitudes'>
            <thead className='tabla-cabecera-solicitudes'>
              <tr className='tabla-cabecera-solicitudes-titulos'>
                <th>Número de solicitud</th>
                <th onClick={() => handleSortChange('estadoOrder')} className='sortable'>
                  Estado 
                  { selectedFilters.estadoOrder === 'undefined' ? 
                  <BsDashLg/> : 
                  selectedFilters.estadoOrder === 'desc' ? 
                  <FaArrowDown/> : 
                  <FaArrowUp/> }

                  <select
                    className='filtros-form-select'
                    name="estado" 
                    id="estado" 
                    value={selectedFilters.estado}
                    onClick={(e) => e.stopPropagation()}
                    onChange={handleStateChange} >
                      {Object.keys(ESTADOS).map(key => (
                        <option key={key} value={key === '' ? undefined : key}>
                          {ESTADOS[key]}
                        </option>
                      ))}
                  </select>
                </th>
                <th>Ítems</th>
                <th onClick={() => handleSortChange('fechaCreacionDateOrder')} className='sortable'>
                  Fecha de Creación
                  { selectedFilters.fechaCreacionDateOrder === 'undefined' ? 
                  <BsDashLg/> : 
                  selectedFilters.fechaCreacionDateOrder === 'desc' ? 
                  <FaArrowDown/> : 
                  <FaArrowUp/> }
                  
                  <input
                    type="date"
                    name="fechaCreacionDate"
                    id="fechaCreacionDate"
                    className="filtros-form-input"
                    value={selectedFilters.fechaCreacionDate}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      e.stopPropagation();
                      setSelectedFilters({ ...selectedFilters, fechaCreacionDate: e.target.value });
                    }}
                  />
                </th>

                <th onClick={() => handleSortChange('fechaCreacionOrder')} className='sortable'>
                Fecha de Retiro
                  { selectedFilters.fechaCreacionOrder === 'undefined' ? 
                  <BsDashLg/> : 
                  selectedFilters.fechaCreacionOrder === 'desc' ? 
                  <FaArrowDown/> : 
                  <FaArrowUp/> }

                  <input
                    type="date"
                    name="fechaCreacion"
                    id="fechaCreacion"
                    className="filtros-form-input"
                    value={selectedFilters.fechaCreacion}
                    onChange={(e) => {
                      e.stopPropagation();
                      setSelectedFilters({ ...selectedFilters, fechaCreacion: e.target.value });
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </th>
                
                <th onClick={() => handleSortChange('fechaResolucionOrder')} className='sortable'>
                  Fecha de Devolución
                  { selectedFilters.fechaResolucionOrder === 'undefined' ? 
                  <BsDashLg/> : 
                  selectedFilters.fechaResolucionOrder === 'desc' ? 
                  <FaArrowDown/> : 
                  <FaArrowUp/> }

                    <input
                      type="date"
                      name="fechaResolucion"
                      id="fechaResolucion"
                      className="filtros-form-input"
                      value={selectedFilters.fechaResolucion}
                      onChange={(e) => {
                        e.stopPropagation();
                        setSelectedFilters({ ...selectedFilters, fechaResolucion: e.target.value });
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                </th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody className='tabla-cuerpo-solicitudes'>
              {pedidos &&
                pedidos !== null &&
                pedidos !== undefined &&
                pedidos.length !== 0 &&
                pedidos.map((pedido, index) => {
                  return (
                    <tr key={index}>
                      <td>{pedido.id}</td>
                      <td>
                        {pedido.estado}

                      </td>
                      <td>{pedido.items} <img src={eyeblue} alt='icon-eye-blue' id='Ojito' onClick={() => handleShowItems(pedido)} /> </td>
                      <td>{new Date(pedido.pedido_creation_date).toLocaleDateString('es-ES')}</td>
                      <td>{new Date(pedido.fecha_creacion).toLocaleDateString('es-ES')}</td>
                      <td>{new Date(pedido.fecha_resolucion).toLocaleDateString('es-ES')}</td>
                      <td>{obtenerAccion(pedido.estado, pedido.id, pedido.descripcion)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <Paginator 
        currentPage={selectedFilters.page} 
        handlePageChange={handlePageChange} 
        totalPageCount={cantidadPaginas} 
      />
        </>
      )}
       {showOverlay && <Overlay message={overlayMessage} onClose={() => setShowOverlay(false)} showConfirmation={showConfirmation} onConfirm={handleConfirmCancel} />}
       {mostrarComponente && <Showitems onClose={() => setMostrarComponente(false)} pedido={selectedPedido} handleShowCheckList={handleShowCheckList} />}
       {showCheckList && (
        <CheckListProf
          mostrarCheckList={showCheckList}
          setMostrarCheckList={setShowCheckList}
          solicitud={selectedPedido}
        />
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  pedidos: state.Pedidos.pedidosUsuario,
  cantidadPaginas: state.Pedidos.totalPaginas
});

export default connect(mapStateToProps, { get_pedidos })(SolicitudList);
